import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App } from 'src/app/model/app.model';
import { FirebaseService } from 'src/app/service/firebase.service';
import { CONFIG } from 'src/config';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit {

  public product: App;
  public productLineKey: string;
  public CONFIG = CONFIG;

  constructor(
    public router: Router,
    private firebaseService: FirebaseService
  ) { }

  async ngOnInit(): Promise<void> {
    const appId = this.router.url.split('/')[2];
    this.product = await this.firebaseService.getAppById(appId);
  }
}
