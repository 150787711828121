export const SERVICES = [
  {
    title: 'Web Application',
    image: 'assets/icons/web-application.avif'
  },
  {
    title: 'Mobile Application',
    image: 'assets/icons/mobile-application.avif'
  },
  {
    title: 'Data Storage and management',
    image: 'assets/icons/data-storage-management.avif'
  },
  {
    title: 'Cloud Services',
    image: 'assets/icons/cloud-services.avif'
  },
  {
    title: 'IT Consulting',
    image: 'assets/icons/it-consulting.jpeg'
  }
];