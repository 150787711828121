export const firebaseConfig = {
    apiKey: "AIzaSyA0Qthim5Nqg9Dt8xkQjA5VTb_rBiY57lw",
    authDomain: "mobtechi.firebaseapp.com",
    projectId: "mobtechi",
    storageBucket: "mobtechi.appspot.com",
    messagingSenderId: "206979011619",
    appId: "1:206979011619:web:ee7ba51044671f3b6f4d96",
    measurementId: "G-FWN73LHVWE",
    databaseURL: "https://mobtechi-default-rtdb.asia-southeast1.firebasedatabase.app",
    firebaseStorageURL: "https://firebasestorage.googleapis.com/v0/b/mobtechi.appspot.com/o/",
};