<nav class="top-nav" #dropdownMenuParent>
  <a [routerLink]="'/'" class="brand">
    <img [src]="config.logo" alt="logo" class="brand-logo" height="40" width="40" />
    <div class="brand-name">
      <h2 class="title"><span class="mob">Mob</span><span class="techi">Techi</span></h2>
      <p class="version">v{{ config.version }}</p>
    </div>
  </a>
  <div class="left-item-wrapper">
    <a *ngFor="let menu of TOP_NAV_MENUS" [routerLink]="menu.url" [class.active]="routerUrl.includes(menu.url)">
      <i class="fas fa-fw" [class]="menu.i" aria-hidden="true"></i>
      <span class="header-title">{{ menu.title }}</span>
    </a>
  </div>
  <!-- Social Media -->
  <div class="social-media">
    <app-social-media></app-social-media>
  </div>
</nav>
<div [class.landing-layout-top-nav-loading-bar]="loading"></div>