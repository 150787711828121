import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { sortByNumber, SORTING_TYPES } from 'src/utils';
import { App } from '../model/app.model';

@Injectable({
    providedIn: 'root',
})

export class FirebaseService {
    private appDbPath = '/apps';
    public appsListSubject: BehaviorSubject<App[]>;

    constructor(private db: AngularFireDatabase) {
        this.appsListSubject = new BehaviorSubject<App[]>(null);
    }

    public async getApps(): Promise<App[]> {
        if (this.appsListSubject.value) {
            // If appsListSubject already has a value, return it as a resolved promise
            return this.appsListSubject.value;
        } else {
            // Otherwise, fetch data from the database and resolve the promise with it
            const data = await firstValueFrom(this.db.object(this.appDbPath).valueChanges());
            const apps = this.normalize(data);
            this.appsListSubject.next(apps);
            return apps;
        }
    }

    public async getAppById(appId) {
        const appsList = await this.getApps();
        return appsList.find((app) => app.id === appId);
    }

    private normalize(appData = {}) {
        const appList = [];
        Object.keys(appData).forEach((key) => {
            const app: App = appData[key];
            if (app.active) {
                appList.push(app);
            }
        });
        return sortByNumber(appList, 'sequence', SORTING_TYPES.ascending);
    }
}
