import { Component } from '@angular/core';
import { SERVICES } from 'src/app/constants/helper-constants';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {

  public servicesList = SERVICES;

}
