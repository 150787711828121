import { Component } from '@angular/core';
import { CONFIG } from 'src/config';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent {

  public contactUs = Object.values(CONFIG.contactUs);

}