<!-- Contact Us Form Start -->
<div style="position: relative;">
  <div class="top-svg svg-div">
    <img firebaseImgLoader="assets/icons/wave.svg">
  </div>
  <app-title title="Contact Us"></app-title>

  <div class="websites-wrapper">
    <ng-container *ngFor="let contact of contactUs">
      <a [href]="contact.url" target="_blank">
        <oth-brand-button class="oth-brand-button" [icon]="contact.cssClass" [text]="contact.name">
        </oth-brand-button>
      </a>
    </ng-container>
  </div>
  
  <div class="footer">
    <div class="footer-card-wrapper">
      <div class="footer-card-right-column">
        <h4>Have any questions or interested in collaboration opportunities? Drop us an email!
        </h4>
      </div>
    </div>
  </div>
</div>
<!-- Contact Us Form End -->

<!-- Contact Information -->
<!-- <app-title title="Contact Information">
</app-title>
<div class="contact-information-wrapper">
  <app-contact-information-card></app-contact-information-card>
</div> -->