import { Component } from '@angular/core';
import { CONFIG } from 'src/config';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent {

  public socialMedia = Object.values(CONFIG.socialMedia);

}
