<div class="product-page-content">
  <img class="wave-svg" firebaseImgLoader="assets/icons/wave.svg">
  <div class="intro">
    <div class="left" *ngIf="product">
      <oth-card [bodyTemplate]="bodyTemplate" [cardClass]="'transparent-card'" [headerTemplate]="headerTemplate"
        class="product-card">
        <ng-template #headerTemplate>
          <div class="header">
            <h3>{{ product.title }}</h3>
          </div>
        </ng-template>
        <ng-template #bodyTemplate>
          <div class="body">
            <img [src]="product.image" alt="product.url" class="app-icon" />
            <p>{{ product.description }}</p>
            <div class="google-play-badge">
              <a href='https://play.google.com/store/apps/details?id={{product.package}}&pcampaignid=web_share&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template #bottomWrapperTemplate></ng-template>
      </oth-card>
    </div>
  </div>

  <!-- <div class="screenshots-wrapper" *ngIf="app.screenshots">
    <app-screenshot-swiper [screenshots]="app.screenshots"></app-screenshot-swiper>
  </div> -->
</div>