import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NAVIGATIONS } from 'src/app/constants/navigation-constants';
import { SeoMetaData, SeoService } from 'src/app/service/seo.service';
import { CONFIG } from 'src/config';
import { SERVICES } from '../constants/helper-constants';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationInterceptor  {
  constructor(private seoService: SeoService, private firebaseService: FirebaseService) {
  }

  public async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    Promise<Observable<boolean | UrlTree> |
      Promise<boolean | UrlTree> |
      boolean |
      UrlTree> {
    const navObj = await this.getObjectByUrl(state.url);
    if (navObj && navObj.title) {
      const services = SERVICES.map((item) => item.title);
      const apps = (await this.firebaseService.getApps())?.map((item) => item.title) || [];
      const keywords = [CONFIG.title, 'Open source app', 'open source game', 'Open source website', ...services, ...apps];
      const seoMetaData: SeoMetaData = {
        title: navObj.title,
        description: navObj.description,
        image: navObj.image,
        robots: keywords,
        keywords
      };
      this.seoService.setMetaData(seoMetaData);
    }

    return true;
  }

  public async getObjectByUrl(url: string) {
    const urlPathValues = url.split('/');
    const lastPath = urlPathValues.pop();
    if (urlPathValues.length > 1) {
      // apps viewing pages
      const app = (await this.firebaseService.getApps())?.find((app) => app.id === lastPath);
      return {
        title: app.title,
        description: `${app.description} powere by ${CONFIG.title}. ${NAVIGATIONS.apps.description}`,
        image: app.image
      }
    } else {
      // normal pages
      const pageSeo = Object.values(NAVIGATIONS).find((nav) => nav.url === lastPath);
      return { ...pageSeo , image: CONFIG.logo };
    }
  };
}
