<swiper
    [autoplay]="{ delay: 2500, disableOnInteraction: false }"
    [breakpoints]="breakpoints"
    [loopFillGroupWithBlank]="true"
    [loop]="true"
    [navigation]="isNavigationEnabled"
    [pagination]="isPaginationEnabled ? { clickable: true } : undefined"
    [slidesPerView]="1"
    [spaceBetween]="10">
  <ng-template *ngFor="let screenshot of screenshots" swiperSlide>
    <div class="content" (click)="openScreenshot(screenshot.src)">
      <img [firebaseImgLoader]="screenshot.image" [alt]="screenshot.title" class="brand-logo" />
      <p>{{ screenshot.title }}</p>
    </div>
  </ng-template>
</swiper>
