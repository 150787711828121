<swiper
    [autoplay]="{ delay: 2500, disableOnInteraction: false }"
    [breakpoints]="breakpoints"
    [loopFillGroupWithBlank]="true"
    [loop]="true"
    [navigation]="isNavigationEnabled"
    [pagination]="isPaginationEnabled ? { clickable: true } : undefined"
    [slidesPerView]="1"
    [spaceBetween]="10">
  <ng-template *ngFor="let app of apps" swiperSlide>
    <img [height]="height" [src]="app.image" alt="logo" class="brand-logo" width="auto" (click)="openProduct(app.id)" />
  </ng-template>
</swiper>
