<div>
  <div class="page">
    <div class="header">
      <header-nav></header-nav>
    </div>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer-nav *ngIf="getIsFooterShow"></app-footer-nav>
  <app-bottom-nav></app-bottom-nav>
</div>